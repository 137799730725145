import React from 'react';
import Typography from '@lobox/uikit/Typography';
import useOpenConfirm from '@lobox/uikit/Confirmation/useOpenConfirm';
import {
  useReactMutation,
  useTranslation,
  useUpdateInfinityData,
} from '@lobox/utils';
import { useGetChatRoomsListQueryKey } from '@shared/components/Organism/Message/hooks/index';
import { messagingApi } from '../utils/api';
import {
  useMessageDispatch,
  useMessageState,
} from '../context/message/message.provider';

const useLeaveHandler = () => {
  const { openConfirmDialog } = useOpenConfirm();
  const { t } = useTranslation();
  const queryKey = useGetChatRoomsListQueryKey();
  const chatRoomsCacheData = useUpdateInfinityData(queryKey);
  const dispatch = useMessageDispatch();
  const activeRoom = useMessageState('activeRoom');

  const { mutate: leaveRoom, ...rest } = useReactMutation({
    apiFunc: messagingApi.leaveRoom,
  });

  const leaveHandler = ({ id }: { id: string }) =>
    openConfirmDialog({
      title: t('leave_g'),
      message: t('leave_helper'),
      confirmButtonText: t('leave_g'),
      cancelButtonText: t('cancel'),
      confirmCallback: () =>
        leaveRoom(
          { id },
          {
            onSuccess: () => {
              chatRoomsCacheData.remove(id);
              if (id === activeRoom?.id) {
                dispatch({ type: 'CLOSE_MESSAGE_CONVERSATION' });
                dispatch({ type: 'CLOSE_RIGHT_SECTION' });
              }
            },
          }
        ),
    });

  return { leaveHandler, ...rest };
};

export default useLeaveHandler;
