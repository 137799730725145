import { useReactQuery } from '@lobox/utils';
import { messagingApi } from '@shared/components/Organism/Message/utils/api';
import { useState } from 'react';
import type { IChatRoom } from '@shared/types/messaging';
import randomIntFromInterval from '@shared/components/Organism/Message/utils/randomIntBetweenTwoNumber';

const useGetLastActivity = () => {
  const [usersId, setUsersId] = useState([]);

  const reactQuery = useReactQuery<IChatRoom>({
    action: {
      key: ['lastActivity'],
      apiFunc: messagingApi.getUsersLastActivity,
      params: { users: usersId },
    },
    config: {
      enabled: !!usersId?.length,
      refetchOnReconnect: true,
      refetchOnMount: true,
      refetchInterval: 1000 * 60 * 2 + randomIntFromInterval(-1, 1) * 1000 * 30,
    },
  });

  const updateLastActivity = (users: Array<string>) => setUsersId(users);

  return {
    ...reactQuery,
    updateLastActivity,
  };
};

export default useGetLastActivity;
