import {
  QueryKeys,
  useReactQuery,
  useStateCallback,
  useTranslation,
} from '@lobox/utils';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import useToast from '@lobox/uikit/Toast/useToast';
import { useGetMessageObject } from '@shared/components/Organism/Message/hooks/index';
import type { SetStateCallbackGeneric } from '@lobox/utils';
import { archiverApi } from '../utils/api';
import type { UseMessageInfiniteQueryType } from './useMessageInfiniteQuery';
import type { IMessage, RoomMessageResponse } from '@shared/types/messaging';

type UseGetRoomMessagesType = Omit<
  UseMessageInfiniteQueryType<IMessage>,
  'fetchNextPage'
> & {
  fetchNextPage: () => void;
  setIsFetchingNextPage: SetStateCallbackGeneric<any>;
};

const useGetRoomMessages = ({
  activeRoomId,
  isGroupChat,
  options,
}: {
  activeRoomId: string;
  isGroupChat: boolean;
  options?: any;
}): UseGetRoomMessagesType => {
  const { authObject } = useGetMessageObject();
  const roomMessageKey = [QueryKeys.getRoomMessages, activeRoomId];
  const [isFetchingNextPage, setIsFetchingNextPage] = useStateCallback(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const authUserId = authObject.id;

  useEffect(() => {
    setHasNextPage(true);
  }, [activeRoomId]);

  const lastMessageFunc = () =>
    isGroupChat
      ? archiverApi.getRoomLastMessages({
          authUserId,
          groupId: activeRoomId,
          count: 20,
        })
      : archiverApi.getOneByOneLastMessages({
          authUserId,
          username: activeRoomId,
          count: 20,
        });

  const result = useReactQuery<RoomMessageResponse>({
    action: {
      key: roomMessageKey,
      apiFunc: lastMessageFunc,
    },
    config: {
      refetchOnReconnect: false,
      ...options,
    },
  });

  const fetchNextPage = async () => {
    const lastMessage = result?.data?.content?.[0];
    try {
      if (lastMessage?.messageGuId) {
        const props = {
          authUserId,
          username: activeRoomId,
          id: lastMessage?.pgid,
          count: 20,
        };
        const nextPageFunc = () =>
          isGroupChat
            ? archiverApi.getRoomPreviousMessages(props)
            : archiverApi.getOneByOnePreviousMessages(props);
        const response = await nextPageFunc();
        queryClient.setQueriesData(
          roomMessageKey,
          (prev: RoomMessageResponse) => ({
            content: [...(response?.content || []), ...(prev?.content || [])],
            lastMessage: response?.lastMessage,
          })
        );
        setHasNextPage(response?.content?.length !== 0);
        setIsFetchingNextPage(false);
      } else {
        setHasNextPage(false);
        setIsFetchingNextPage(false);
      }
    } catch (e) {
      setHasNextPage(false);
      setIsFetchingNextPage(false);
      toast({
        message: t('could_n_f_p_msg'),
        icon: 'exclamation-triangle',
      });
    }
  };

  return {
    ...result,
    data: result?.data?.content,
    fetchNextPage,
    isFetchingNextPage,
    setIsFetchingNextPage,
    hasNextPage,
  };
};

export default useGetRoomMessages;
