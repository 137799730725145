import {
  roomMessageNormalizer,
  staredMessageNormalizer,
  wsMessageToMessage,
} from './archiver';

const messageNormalizer = {
  roomMessages: roomMessageNormalizer,
  staredMessages: staredMessageNormalizer,
  wsMessageToMessage,
};

export default messageNormalizer;
