import {
  uploadFileWithCancel,
  uploadFile,
  useReactMutation,
} from '@lobox/utils';
import { useRef } from 'react';

const useFileUpload = () => {
  const cancelFileUpload = useRef(null);
  //   const { cancelUpload, upload: uploadFile } = uploadFileWithCancel();

  const { mutate } = useReactMutation({
    apiFunc: uploadFile,
  });

  const upload = (data: any, options: any) => {
    mutate({ ...data, cancelRef: cancelFileUpload }, options);
  };
  const cancelUpload = () => {
    if (cancelFileUpload.current) {
      cancelFileUpload.current('canceled manually');
    }
  };
  return { upload, cancelUpload };
};

export default useFileUpload;
