import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import useOpenConfirm from '@lobox/uikit/Confirmation/useOpenConfirm';
import useToast from '@lobox/uikit/Toast/useToast';
import React, { useCallback } from 'react';
import {
  useReactMutation,
  useTranslation,
  useUpdateInfinityData,
} from '@lobox/utils';
import {
  useMessageDispatch,
  useMessageState,
} from '../context/message/message.provider';
import type { IChatRoom } from '@shared/types/messaging';
import { messagingApi } from '../utils/api';
import classes from './useDeleteRoom.module.scss';

interface UseDeleteRoomProps {
  onSuccess?: (args: any) => void;
  queryKey: string | Array<string>;
}

const useDeleteRoom = ({
  queryKey,
  onSuccess,
}: Partial<UseDeleteRoomProps> = {}) => {
  const { openConfirmDialog } = useOpenConfirm();
  const { t } = useTranslation();
  const toast = useToast();
  const dispatch = useMessageDispatch();
  const activeRoom = useMessageState('activeRoom');
  const { mutate: deleteRoom } = useReactMutation({
    apiFunc: messagingApi.deleteRoom,
  });
  const { remove } = useUpdateInfinityData(queryKey);

  const deleteRoomHandler = useCallback(
    (room: IChatRoom) => () =>
      openConfirmDialog({
        title: room.isGroupChat ? t('delete_group') : t('delete_chat'),
        message: (
          <Flex className={classes.messageWrap}>
            <Typography>{t('r_y_s_y_w_t_delete')}</Typography>
            <Typography font="bold" ml={4}>
              {room.name}
            </Typography>
          </Flex>
        ),
        confirmButtonText: t('confirm'),
        cancelButtonText: t('cancel'),
        confirmCallback: () => {
          deleteRoom(
            { id: room.id },
            {
              onSuccess: (props) => {
                remove(room.id);
                if (room.id === activeRoom?.id) {
                  dispatch({
                    type: 'CLOSE_MESSAGE_CONVERSATION',
                  });
                  dispatch({
                    type: 'CLOSE_CONTACT_INFO',
                  });
                }
                toast({
                  type: 'success',
                  icon: 'check-circle',
                  title: room.isGroupChat
                    ? t('delete_group_succ')
                    : t('delete_chat_succ'),
                });
                onSuccess?.(props);
              },
            }
          );
        },
      }),
    []
  );
  return { deleteRoomHandler };
};

export default useDeleteRoom;
