import { QueryKeys, useReactQuery } from '@lobox/utils';
import messageNormalizer from '@shared/components/Organism/Message/utils/normalizers';
import { useGetMessageObject } from '@shared/components/Organism/Message/hooks/index';
import type { IChatRoom } from '@shared/types/messaging';
import { getRoomsDetail } from '../utils/api/room';
import { useMessageState } from '../context/message/message.provider';

const useGetRoomDetail = ({ roomId }: { roomId?: string } = {}) => {
  const activeRoom: IChatRoom = useMessageState('activeRoom');
  const activeRoomId = roomId || activeRoom?.id;
  const { authObject } = useGetMessageObject();

  const { data: details, ...rest } = useReactQuery<IChatRoom>({
    action: {
      key: [QueryKeys.getRoomDetail, activeRoomId],
      apiFunc: getRoomsDetail,
      params: { id: activeRoomId },
    },
    config: {
      enabled: activeRoom?.isGroupChat,
    },
  });
  return {
    data: {
      ...(details || {}),
      isOwner: details?.owner === authObject.id,
      pinnedMessage: details?.pinmsg
        ? messageNormalizer.wsMessageToMessage({
            wsMessage: JSON.parse(details.pinmsg as any),
            users: {},
            authUserId: authObject.id,
          })
        : undefined,
    },
    ...rest,
  };
};

export default useGetRoomDetail;
