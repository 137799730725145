import { removeEmptyFromObject } from '@lobox/utils';
import {
  messageSendingStatus,
  messagesVariations,
  messageTypes,
} from '../../constants';
import type { ISuggestObject } from '@lobox/utils';
import type { IWsMessage, RoomMessageResponse } from '@shared/types/messaging';
import getMessageVariant from '../getMessageVariant';
import type { IMessageRoom } from '@shared/types/messaging/message';

export const wsMessageToMessage = ({
  wsMessage: serverMessage,
  authUserId,
  users,
  room,
}: {
  authUserId: string;
  wsMessage: IWsMessage;
  users: { [key: string]: ISuggestObject };
  room?: IMessageRoom;
}) => {
  const {
    id,
    guid: messageGuId,
    to: roomId,
    uid: userId,
    meta: file,
    text,
    muc,
    type: wsMessageType,
    parent,
    del,
    data: messageOptionalData = {},
    seen,
    createdAt,
    star,
    pgid,
  } = serverMessage;
  const user = users?.[userId];
  const parentUser = users?.[parent?.uid];
  const isMine = authUserId === userId;
  const isDeleted = del === 1;

  const parentMessage =
    parent && !isDeleted
      ? {
          isMine: authUserId === parent.uid,
          isSeen: parent.seen === 1,
          isStared: parent.star === 1,
          user: {
            id: parent.uid,
            username: parentUser?.username,
            fullName: parentUser?.fullName,
            ownerName:
              parent?.uid === authUserId ? 'You' : parentUser?.fullName,
          },
          id: parent.id,
          messageGuId: parent.guid,
          pgid: parent.pgid,
          variant: getMessageVariant({
            file: parent.meta,
            messageOptionalData: parent.data,
          }),
          visibleSeenBy: isMine,
          data: {
            createdAt:
              typeof parent.createdAt === 'string'
                ? `${parent.createdAt}Z`
                : parent.createdAt,
            status: messageSendingStatus.SENT,
            type: parent.type,
            isFromServer: true,
            image: parent.meta?.url,
            file: parent.meta,
            text: parent.text || '',
            wsMessage: parent,
            messageOptionalData: parent.data,
          },
        }
      : undefined;

  const parentWsMessage = parentMessage?.data?.wsMessage;
  if (parentWsMessage) {
    parentWsMessage.guid = parentMessage.messageGuId;
  }
  const variant = getMessageVariant({ file, messageOptionalData });
  const isReplied = wsMessageType === messageTypes.MESSAGE_TYPE_REPLY_TEXT;
  const isForward = wsMessageType === messageTypes.MESSAGE_TYPE_FORWARD_TEXT;

  const wsMessage = removeEmptyFromObject<IWsMessage>({
    pgid,
    guid: messageGuId,
    text: text || '',
    type: wsMessageType,
    id,
    muc,
    to: roomId,
    uid: userId,
    meta: file,
    data: messageOptionalData,
    parent: parentWsMessage,
    del,
  });
  const message = {
    isMine,
    isSeen: seen === 1,
    user: {
      id: userId,
      username: user?.username,
      fullName: user?.fullName,
      ownerName: isMine ? 'You' : user?.fullName,
      avatar: user?.croppedImageUrl,
    },
    room,
    id,
    pgid,
    messageGuId,
    variant,
    visibleSeenBy: isMine,
    isReplied,
    isForward,
    isStared: star === 1,
    textAdded:
      variant !== messagesVariations.TEXT || isForward || isReplied
        ? text
        : undefined,
    isDeleted,
    data: {
      createdAt: typeof createdAt === 'string' ? `${createdAt}Z` : createdAt,
      status: messageSendingStatus.SENT,
      type: wsMessageType,
      isFromServer: true,
      image: file?.url,
      file,
      text: text || '',
      wsMessage,
      parentMessage,
      messageOptionalData,
      isEdited: wsMessageType === messageTypes.MESSAGE_TYPE_EDIT_TEXT,
      profile: {
        id: messageOptionalData.pageId || messageOptionalData.userId,
      },
    },
  };
  return message;
};

const roomMessageNormalizer = ({
  messages,
  authUserId,
  users,
}: {
  authUserId: string;
  messages: Array<IWsMessage>;
  users: { [key: string]: ISuggestObject };
}): RoomMessageResponse => ({
  content: messages?.reduce((prev, curr) => {
    const message = wsMessageToMessage({
      wsMessage: curr,
      authUserId,
      users,
    });
    return [message, ...prev];
  }, []),
});

const staredMessageNormalizer = ({
  messages,
  authUserId,
  users,
}: {
  authUserId: string;
  messages: Array<{ message: string }>;
  users: { [key: string]: ISuggestObject };
}): RoomMessageResponse => ({
  content: messages?.reduce((prev, curr) => {
    const { message: msg, ...rest } = curr;
    const room =
      curr.pubsub === 1
        ? { id: curr.to, title: curr.name }
        : { id: curr.to, title: users?.[curr.to]?.fullName };
    const message = wsMessageToMessage({
      wsMessage: {
        ...JSON.parse(msg),
        ...rest,
        isStared: true,
      },
      authUserId,
      users,
      room,
    });
    return [message, ...prev];
  }, []),
});

export { roomMessageNormalizer, staredMessageNormalizer };
