import { APP_ENTITIES, QueryKeys, useUpdateInfinityData } from '@lobox/utils';
import useUnblock from '@shared/hooks/api-hook/useUnblock';
import { useGetChatRoomsListQueryKey } from '@shared/components/Organism/Message/hooks/index';
import type { IChatRoom } from '@shared/types/messaging';
import {
  useMessageDispatch,
  useMessageState,
} from '../context/message/message.provider';

const useUnBlockHandler = () => {
  const dispatch = useMessageDispatch();
  const { unblock, ...rest } = useUnblock();
  const activeRoom = useMessageState('activeRoom');
  const queryKey = useGetChatRoomsListQueryKey();
  const chatRoomsCacheData = useUpdateInfinityData(queryKey);
  const archivedMessagesCacheData = useUpdateInfinityData(
    QueryKeys.archivedMessages
  );

  const unBlockHandler = (room: IChatRoom) => () => {
    unblock(
      {
        entityId: room.id,
        entityType: APP_ENTITIES.person,
      },
      {
        onSuccess: () => {
          chatRoomsCacheData.replace({ id: room.id }, (oldData: any) => ({
            ...oldData,
            isBlocked: false,
          }));
          archivedMessagesCacheData.replace(
            { id: room.id },
            (oldData: any) => ({
              ...oldData,
              isBlocked: false,
            })
          );
          if (activeRoom?.id === room.id) {
            dispatch({
              type: 'SET_ACTIVE_ROOM',
              payload: { room: { ...room, isBlocked: false } },
            });
          }
        },
      }
    );
  };

  return { unBlockHandler, ...rest };
};

export default useUnBlockHandler;
