import {
  APP_ENTITIES,
  getObjectDetailByIds,
  messageEndPoints,
  request,
} from '@lobox/utils';
import messageNormalizer from '../normalizers';
import type { ListResponse, PaginateParamType } from '@lobox/utils';
import { roomMemberRoles } from '../../constants';
import type {
  IChatRoom,
  ICreateRoomData,
  ICreateRoomResponse,
} from '@shared/types/messaging/room';

export const getJoinedRoomsList = async (): Promise<
  ListResponse<IChatRoom>
> => {
  const { data } = await request.get(messageEndPoints.getJoinedRoomsList);
  return {
    content: data.map(({ pg_id, ...item }) => ({
      ...item,
      isGroupChat: true,
      pgId: pg_id,
    })),
  };
};

export const getChatRoomsList = async (
  params: PaginateParamType & { archived?: boolean }
): Promise<ListResponse<IChatRoom>> => {
  const lastPageId = params?.page?.last || '-1';
  const { data } = await request.get(
    messageEndPoints.getChatDialog({ lastId: lastPageId, count: params.size })
  );

  const usersId = data?.messages
    ?.reduce(
      (prev, curr: IChatRoom) =>
        curr.pubsub === 0
          ? [...prev, Math.round(curr.peer)]
          : [...prev, Math.round(curr.owner)],
      []
    )
    .filter(Boolean)
    .join();
  const users = usersId ? await getObjectDetailByIds(usersId) : {};

  const content = data?.messages.map(
    ({
      icon,
      name,
      owner,
      peer,
      count,
      pubsub,
      last,
      message,
      createdAt,
      muted,
      archived,
    }: any) => {
      const isGroupChat = pubsub === 1;
      const user = isGroupChat ? users?.[owner] : users?.[peer];
      const lastMessage = messageNormalizer.wsMessageToMessage({
        wsMessage: {
          ...JSON.parse(message),
          createdAt,
        },
        users: {},
        authUserId: undefined,
      });
      return {
        isPage: isGroupChat ? false : user?.userType === APP_ENTITIES.page,
        icon: isGroupChat ? icon : user?.croppedImageUrl,
        username: isGroupChat ? undefined : user?.username,
        usernameAtSign: isGroupChat ? undefined : `@${user?.username}`,
        name: isGroupChat ? name : user?.fullName,
        owner,
        ownerUserName: isGroupChat ? user?.username : undefined,
        ownerName: isGroupChat ? user?.fullName : undefined,
        isGroupChat,
        id: peer,
        youAreBlocked: !isGroupChat ? user?.youAreBlocked : undefined,
        youHaveBlocked: !isGroupChat ? user?.youHaveBlocked : undefined,
        isBlocked: user?.youHaveBlocked,
        unReadMessageCount: count,
        last,
        lastMessage,
        createdAt,
        isMuted: muted === 1,
        isArchived: archived === 1,
      };
    }
  );
  return {
    content,
  };
};

export const getRoomMembers = async ({
  id,
}: {
  id: string;
}): Promise<ListResponse<IChatRoom>> => {
  const { data } = await request.get(
    `${messageEndPoints.getRoomMembers}/${id}/-1/20`
  );
  const usersId = data.map((item) => Math.round(item.username)).join();
  const users = usersId ? await getObjectDetailByIds(usersId) : {};

  return {
    content: data?.map(({ role, ...item }: any) => {
      const user = users?.[item.username];
      const isAdmin = role === roomMemberRoles.Admin;
      const isOwner = role === roomMemberRoles.Owner;

      return {
        ...item,
        role,
        isPage: user.userType === APP_ENTITIES.page,
        id: item.username,
        fullName: user?.fullName,
        subTitle: user?.subTitle,
        type: user?.type,
        username: user?.username,
        avatar: user?.croppedImageUrl,
        isAdmin,
        isOwner,
        order: isOwner ? 1 : isAdmin ? 2 : 3,
      };
    }),
  };
};

export const getRoomsDetail = async ({
  params,
}: {
  params: { id: string };
}): Promise<Partial<IChatRoom>> => {
  const { id } = params;
  const { data } = await request.get(`${messageEndPoints.getRoomDetail}/${id}`);
  return data;
};

export const getRoomMemberInfo = async ({
  params,
}: {
  params: { groupId: string; userId: string };
}): Promise<Partial<IChatRoom>> => {
  const { groupId, userId } = params;
  const { data } = await request.get(
    messageEndPoints.roomMemberInfo({ groupId, userId })
  );
  return data;
};

export const createRoom = async (
  roomData: Partial<ICreateRoomData>
): Promise<ICreateRoomResponse> => {
  const { data } = await request.post(messageEndPoints.createRoom, roomData);

  return data;
};

export const updateRoom = async (
  roomData: Partial<ICreateRoomData>
): Promise<ICreateRoomResponse> => {
  const { data } = await request.post(messageEndPoints.updateRoom, roomData);

  return data;
};

export const deleteRoom = async (body: { id: string }): Promise<any> => {
  const { data } = await request.post(messageEndPoints.deleteRoom, body);

  return data;
};

export const blockUser = async (body: { user: string }): Promise<any> => {
  const { data } = await request.post(messageEndPoints.blockUser, body);

  return data;
};

export const unBlockUser = async (body: { user: string }): Promise<any> => {
  const { data } = await request.post(messageEndPoints.unBlockUser, body);

  return data;
};

export const leaveRoom = async (body: { id: string }): Promise<any> => {
  const { data } = await request.post(messageEndPoints.leaveRoom, body);
  return data;
};

export const markAsAdmin = async (body: {
  id: string;
  username: string;
}): Promise<any> => {
  const { data } = await request.post(messageEndPoints.markAsAdmin, body);
  return data;
};

export const removeAdmin = async (body: {
  id: string;
  username: string;
}): Promise<any> => {
  const { data } = await request.post(messageEndPoints.removeAdmin, body);
  return data;
};

export const removeMember = async (body: {
  id: string;
  username: string;
}): Promise<any> => {
  const { data } = await request.post(messageEndPoints.removeMember, body);
  return data;
};

export const addMember = async (body: {
  id: string;
  users: Array<string>;
}): Promise<any> => {
  const { data } = await request.post(messageEndPoints.addMember, body);
  return data;
};

export const muteRoom = async (body: { peer: string }): Promise<any> => {
  const { data } = await request.post(messageEndPoints.muteRoom, body);
  return data;
};
export const unMuteRoom = async (body: { peer: string }): Promise<any> => {
  const { data } = await request.post(messageEndPoints.unMuteRoom, body);
  return data;
};

export const getUsersLastActivity = async ({
  params,
}: {
  params: {
    users: Array<string>;
  };
}): Promise<any> => {
  const { data } = await request.post(
    messageEndPoints.getUsersLastActivity,
    params
  );
  return data?.list?.reduce((prev: any, curr: any) => {
    return {
      ...prev,
      [curr.uid]: curr.last_activity,
    };
  }, {});
};

export default {
  createRoom,
  deleteRoom,
  getRoomsDetail,
  getRoomMembers,
  getJoinedRoomsList,
  blockUser,
  unBlockUser,
  leaveRoom,
  updateRoom,
  markAsAdmin,
  removeAdmin,
  addMember,
  removeMember,
  getChatRoomsList,
  getRoomMemberInfo,
  getUsersLastActivity,
  unMuteRoom,
  muteRoom,
};
