import { useCallback } from 'react';
import { useReactMutation, useUpdateInfinityData } from '@lobox/utils';
import {
  useMessageDispatch,
  useMessageState,
} from '../context/message/message.provider';
import type { IChatRoom } from '@shared/types/messaging';
import { messagingApi } from '../utils/api';

interface UseDeleteRoomProps {
  onSuccess?: (args: any) => void;
  queryKey: string | Array<string>;
}

const useUnMuteHandler = ({
  queryKey,
  onSuccess,
}: Partial<UseDeleteRoomProps> = {}) => {
  const dispatch = useMessageDispatch();
  const activeRoom = useMessageState('activeRoom');
  const { mutate: muteRoom } = useReactMutation({
    apiFunc: messagingApi.unMuteRoom,
  });
  const { replace } = useUpdateInfinityData<IChatRoom>(queryKey);

  const unMuteHandler = useCallback(
    (room: IChatRoom) => () =>
      muteRoom(
        { peer: room.id },
        {
          onSuccess: (props) => {
            replace({ ...room, isMuted: false });
            if (room.id === activeRoom?.id) {
              dispatch({
                type: 'SET_ACTIVE_ROOM',
                payload: {
                  room: {
                    ...room,
                    isMuted: false,
                  },
                },
              });
            }
            onSuccess?.(props);
          },
        }
      ),
    []
  );
  return { unMuteHandler };
};

export default useUnMuteHandler;
