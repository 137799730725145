import { unblock as unblockApiFunc, useReactMutation } from '@lobox/utils';
import type {
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';
import type { EntityType } from '@lobox/utils';

type UnblockProps = {
  entityId: string;
  entityType: EntityType;
};

type UseUnblockType = Omit<UseMutationResult, 'mutate'> & {
  unblock: (props: UnblockProps, opt?: UseMutationOptions) => void;
};

const useUnblock = (): UseUnblockType => {
  const { mutate, ...rest } = useReactMutation({
    apiFunc: unblockApiFunc,
  });

  const unblock = (
    { entityId, entityType }: UnblockProps,
    options?: UseMutationOptions
  ) => {
    mutate(
      {
        entityId,
        entityType,
      }, // @ts-ignore
      options
    );
  };

  return {
    unblock,
    ...rest,
  };
};

export default useUnblock;
