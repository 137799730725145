function scrollToChatConversationBottom(
  elementId: string,
  delay: number = 1000
): void {
  const containerElement = document.getElementById(elementId);

  if (containerElement) {
    setTimeout(() => {
      containerElement.scrollTop = containerElement.scrollHeight;
    }, delay);
  }
}

export default scrollToChatConversationBottom;
