import * as React from 'react';
import { useEffect, useRef } from 'react';
import type { Action, State } from './messageBubble.reducer';
import messageBubbleReducer from './messageBubble.reducer';
import type { IMessage, SectionNameTypes } from '@shared/types/messaging';

type Dispatch = (action: Action) => void;

type MessageBubbleProviderProps = {
  initialValue: { message?: IMessage; sectionName?: SectionNameTypes };
  children: React.ReactNode;
};

const CountStateContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

function MessageBubbleProvider({
  children,
  initialValue,
}: MessageBubbleProviderProps) {
  const isMountedRef = useRef(false);
  // @ts-ignore
  const [state, dispatch] = React.useReducer(
    messageBubbleReducer,
    initialValue
  );
  const value = { state, dispatch };
  const { sectionName, message } = initialValue;
  const {
    messageGuId,
    isDeleted,
    isStared,
    data: { text, status },
  } = message;

  useEffect(() => {
    if (isMountedRef.current) {
      // @ts-ignore
      dispatch({
        type: 'RESET_INIT_VALUE',
        payload: { message, sectionName },
      });
      console.warn('WHY you call me', initialValue.message);
    }
    isMountedRef.current = true;
  }, [messageGuId, text, status, isDeleted, isStared, sectionName]);

  return (
    <CountStateContext.Provider value={value}>
      {children}
    </CountStateContext.Provider>
  );
}

function useMessageBubbleState() {
  const context = React.useContext(CountStateContext);
  if (context === undefined) {
    throw new Error('useMessageBubble must be used within a CountProvider');
  }
  return context.state;
}

function useMessageBubbleDispatch() {
  const context = React.useContext(CountStateContext);
  if (context === undefined) {
    throw new Error('useMessageBubble must be used within a CountProvider');
  }
  return context.dispatch;
}

export {
  MessageBubbleProvider,
  useMessageBubbleDispatch,
  useMessageBubbleState,
};
