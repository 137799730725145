import { QueryKeys } from '@lobox/utils';
import useGetAppObject from '@shared/hooks/useGetAppObject';

const useGetChatRoomsListQueryKey = () => {
  const { getAppObjectPropValue } = useGetAppObject();
  const id = getAppObjectPropValue({ pageKey: 'id', userKey: 'id' });

  return [QueryKeys.getChatRoomsList, id];
};

export default useGetChatRoomsListQueryKey;
