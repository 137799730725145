import React from 'react';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import useOpenConfirm from '@lobox/uikit/Confirmation/useOpenConfirm';
import {
  APP_ENTITIES,
  QueryKeys,
  useTranslation,
  useUpdateInfinityData,
} from '@lobox/utils';
import useBlock from '@shared/hooks/api-hook/useBlock';
import { useGetChatRoomsListQueryKey } from '@shared/components/Organism/Message/hooks/index';
import type { IChatRoom } from '@shared/types/messaging';
import {
  useMessageDispatch,
  useMessageState,
} from '../context/message/message.provider';
import classes from './useBlockHandler.module.scss';

const useBlockHandler = () => {
  const { openConfirmDialog } = useOpenConfirm();
  const { t } = useTranslation();
  const { block, ...rest } = useBlock();
  const dispatch = useMessageDispatch();
  const activeRoom = useMessageState('activeRoom');
  const queryKey = useGetChatRoomsListQueryKey();
  const chatRoomsCacheData = useUpdateInfinityData(queryKey);
  const archivedMessagesCacheData = useUpdateInfinityData(
    QueryKeys.archivedMessages
  );

  const blockHandler = (room: IChatRoom) => () =>
    openConfirmDialog({
      title: (
        <Flex className={classes.messageWrap}>
          <Typography>{t('block')}</Typography>
          <Typography font="bold" ml={4}>
            {room.name}
          </Typography>
        </Flex>
      ),
      message: <Typography mt={4}>{t('blocking_reasons')}</Typography>,
      confirmButtonText: t('block'),
      cancelButtonText: t('cancel'),
      confirmCallback: () =>
        block(
          { entityId: room.id, entityType: APP_ENTITIES.person },
          {
            onSuccess: () => {
              chatRoomsCacheData.replace({ id: room.id }, (oldData: any) => ({
                ...oldData,
                isBlocked: true,
              }));
              archivedMessagesCacheData.replace(
                { id: room.id },
                (oldData: any) => ({
                  ...oldData,
                  isBlocked: true,
                })
              );
              if (activeRoom?.id === room.id) {
                dispatch({
                  type: 'SET_ACTIVE_ROOM',
                  payload: {
                    room: {
                      ...room,
                      isBlocked: true,
                    },
                  },
                });
              }
            },
          }
        ),
    });

  return { blockHandler, ...rest };
};

export default useBlockHandler;
