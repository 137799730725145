import { useGlobalDispatch } from '@lobox/utils';
import type { IChatRoom } from '@shared/types/messaging';

const useReportRoom = () => {
  const appDispatch = useGlobalDispatch();

  const toggleReportModal = (room: IChatRoom) => {
    const roomId = room.id;

    appDispatch({
      type: 'TOGGLE_REPORT_MODAL',
      payload: {
        isOpen: true,
        data: {
          entityType: 'message',
          entityId: roomId,
        },
      },
    });
  };
  return toggleReportModal;
};

export default useReportRoom;
