import { getObjectDetailByIds, messageEndPoints } from '@lobox/utils';
import type {
  ListResponse,
  PaginateParamType,
  PaginateResponse,
} from '@lobox/utils';
import { request } from '@lobox/utils';
import type {
  IChatRoom,
  ICreateRoomResponse,
  IMessage,
  IWsMessage,
  RoomMessageResponse,
} from '@shared/types/messaging';
import messagesNormalizer from '../normalizers';

export const getRoomLastMessages = async ({
  groupId,
  count,
  authUserId,
}: {
  groupId: string;
  count: number;
  authUserId: string;
}): Promise<RoomMessageResponse> => {
  const { data } = await request.get(
    messageEndPoints.getRoomLastMessages({ groupId, count })
  );
  const usersId = data?.messages
    ?.map((item: any) => Math.round(item.uid))
    .join() as any;
  const users = usersId ? await getObjectDetailByIds(usersId) : {};
  return messagesNormalizer.roomMessages({
    authUserId,
    messages: data.messages,
    users,
  });
};

export const getOneByOneLastMessages = async ({
  username,
  count,
  authUserId,
}: {
  username: string;
  count: number;
  authUserId: string;
}): Promise<RoomMessageResponse> => {
  const { data } = await request.get<{ messages: Array<IWsMessage> }>(
    messageEndPoints.getOneByOneLastMessages({ username, count })
  );
  const usersId = data?.messages
    ?.map((item: any) => Math.round(item.uid))
    .join() as any;
  const users = usersId ? await getObjectDetailByIds(usersId) : {};
  return messagesNormalizer.roomMessages({
    authUserId,
    messages: data.messages,
    users,
  });
};

export const getOneByOnePreviousMessages = async ({
  username,
  count,
  authUserId,
  id,
}: {
  username: string;
  count: number;
  authUserId: string;
  id: string;
}): Promise<RoomMessageResponse> => {
  const { data } = await request.get<{ messages: Array<IWsMessage> }>(
    messageEndPoints.getOneByOnePreviousMessages({ username, count, id })
  );
  return messagesNormalizer.roomMessages({
    authUserId,
    messages: data.messages,
    users: {},
  });
};

export const getRoomPreviousMessages = async ({
  username,
  count,
  authUserId,
  id,
}: {
  username: string;
  count: number;
  authUserId: string;
  id: string;
}): Promise<RoomMessageResponse> => {
  const { data } = await request.get<{ messages: Array<IWsMessage> }>(
    messageEndPoints.getRoomPreviousMessages({ username, count, id })
  );
  const usersId = data?.messages
    ?.map((item: any) => Math.round(item.uid))
    .join() as any;
  const users = usersId ? await getObjectDetailByIds(usersId) : {};
  return messagesNormalizer.roomMessages({
    authUserId,
    messages: data.messages,
    users,
  });
};

export const clearRoomHistory = async (
  groupId: string
): Promise<ICreateRoomResponse> => {
  const { data } = await request.post(
    messageEndPoints.clearRoomHistory(groupId)
  );

  return data;
};

export const clearOneByOneHistory = async (
  username: string
): Promise<ICreateRoomResponse> => {
  const { data } = await request.post(
    messageEndPoints.clearOneByOneHistory(username)
  );

  return data;
};

export const getStarredMessageList = async ({
  authUserId,
}: {
  authUserId: string;
}): Promise<ListResponse<IMessage>> => {
  const { data } = await request.get<{ list: Array<IWsMessage> }>(
    `${messageEndPoints.getStarredMessageList}/-1/50`
  );
  const usersId = data?.list
    ?.reduce((prev, curr) => {
      const msg = JSON.parse(curr.message);
      return [
        ...prev,
        Math.round(msg.uid),
        ...(msg.to && msg.muc !== 1 ? [Math.round(msg.to)] : []),
      ];
    }, [])
    .join();
  const users = usersId ? await getObjectDetailByIds(usersId) : {};

  return messagesNormalizer.staredMessages({
    authUserId,
    messages: data.list,
    users,
  });
};

export const starMessage = async (body: { message: string }): Promise<any> => {
  const { data } = await request.post(messageEndPoints.starMessage, body);
  return data;
};

export const unStarMessage = async (body: {
  user: string;
  mid: string;
}): Promise<any> => {
  const { data } = await request.post(messageEndPoints.unStarMessage, body);
  return data;
};

export const pinMessage = async (body: { message: string }): Promise<any> => {
  const { data } = await request.post(messageEndPoints.pinMessage, body);
  return data;
};

export const unPinMessage = async (body: { message: string }): Promise<any> => {
  const { data } = await request.post(messageEndPoints.unPinMessage, body);
  return data;
};

export const archiveChatRoom = async (body: {
  from: string;
  to: string;
  pubsub: 0 | 1;
}): Promise<any> => {
  const { data } = await request.post(messageEndPoints.archiveMessage, body);
  return data;
};

export const getArchivedMessageList = async (
  params: PaginateParamType
): Promise<PaginateResponse<IChatRoom>> => {
  const lastPageId = params?.page?.id || '-1';
  const { data } = await request.get<{ list: Array<any> }>(
    messageEndPoints.getArchivedMessageList({
      lastId: lastPageId,
      count: params.size,
    })
  );
  const usersId = data?.list
    ?.reduce(
      (prev, curr: IChatRoom) =>
        curr.pubsub === 0 ? [...prev, Math.round(curr.to)] : prev,
      []
    )
    .filter(Boolean)
    .join();
  const users = usersId ? await getObjectDetailByIds(usersId) : {};
  return {
    content: data.list.map(({ owner, username, to, pubsub, pg_id, group }) => {
      const isGroupChat = pubsub === 1;
      const user = users?.[to];
      return {
        id: pg_id,
        username: username,
        isGroupChat,
        owner,
        name: isGroupChat ? group.name : user?.fullName,
        icon: isGroupChat ? group.icon : user?.croppedImageUrl,
        isBlocked: user?.youAreBlocked || user?.youHaveBlocked,
      };
    }),
  };
};

export const getMyPagesMessageNotifications = async (): Promise<any> => {
  const { data } = await request.get(
    messageEndPoints.getPageUnseenMessageCount
  );
  return data;
};

export const getUnseenMessageCount = async (): Promise<{ count: number }> => {
  const { data } = await request.get(
    messageEndPoints.getAuthUserUnseenMessageCount
  );
  return data;
};

export const unArchiveChatRoom = async (body: {
  from: string;
  to: string;
  pubsub: 0 | 1;
}): Promise<any> => {
  const { data } = await request.post(messageEndPoints.unArchiveMessage, body);
  return data;
};

export const resetUnseenMessageCount = async (body: {
  peer: string;
  guid: string;
  pubsub: 0 | 1;
}): Promise<any> => {
  const { data } = await request.post(
    messageEndPoints.resetUnseenMessageCount,
    body
  );
  return data;
};

export const resetAllUnseenMessageCount = async (body: {
  peer: string;
  guid: string;
  pubsub: 0 | 1;
}): Promise<any> => {
  const { data } = await request.post(
    messageEndPoints.resetAllUnseenMessageCount,
    body
  );
  return data;
};

export const searchRoomMessages = async ({
  params,
}: {
  params: {
    isGroupChat?: boolean;
    roomId: string;
    keywords: string;
    page?: number;
    count?: number;
    authUserId: string;
  };
}): Promise<ListResponse<IMessage>> => {
  const {
    isGroupChat,
    roomId,
    keywords,
    page = 0,
    count = 30,
    authUserId,
  } = params;
  const url = isGroupChat
    ? messageEndPoints.searchGroupConversation
    : messageEndPoints.searchOneByOneConversation;

  const { data } = await request.get<ListResponse<IWsMessage>>(url, {
    params: {
      to: roomId,
      page,
      count,
      keywords,
    },
  });

  const usersId = data?.messages
    ?.map((item: any) => Math.round(item.uid))
    .join() as any;
  const users = usersId ? await getObjectDetailByIds(usersId) : {};

  return messagesNormalizer.roomMessages({
    authUserId,
    messages: data.messages,
    users,
  });
};

export const setChantStatus = async (body: {
  ghost: boolean;
}): Promise<any> => {
  const { data } = await request.post(messageEndPoints.setChantStatus, body);
  return data;
};

export const getChatStatus = async (): Promise<any> => {
  const { data } = await request.get(messageEndPoints.setChantStatus);
  return data;
};

export default {
  getRoomLastMessages,
  getOneByOneLastMessages,
  clearRoomHistory,
  getStarredMessageList,
  starMessage,
  pinMessage,
  unPinMessage,
  unStarMessage,
  archiveChatRoom,
  unArchiveChatRoom,
  getArchivedMessageList,
  clearOneByOneHistory,
  getOneByOnePreviousMessages,
  getRoomPreviousMessages,
  resetUnseenMessageCount,
  resetAllUnseenMessageCount,
  searchRoomMessages,
  getUnseenMessageCount,
  setChantStatus,
  getChatStatus,
};
