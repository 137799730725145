import { messageFileTypes, messagesVariations } from '../constants';
import type {
  IMessageFile,
  IMessageOptionalData,
} from '@shared/types/messaging/message';

interface GetMessageVariantProps {
  file: IMessageFile;
  messageOptionalData?: IMessageOptionalData;
}

const getMessageVariant = ({
  file,
  messageOptionalData = {},
}: GetMessageVariantProps) => {
  const {
    userId,
    pageId,
    postId,
    jobId,
    eventId,
    recommendation,
    isCreatedRoomMessage,
    availabilityData,
    meetingId,
    taskId,
  } = messageOptionalData;

  return taskId
    ? messagesVariations.TASK
    : meetingId
    ? messagesVariations.MEETING
    : availabilityData
    ? messagesVariations.AVAILABILITY
    : eventId
    ? messagesVariations.BIRTHDAY
    : isCreatedRoomMessage
    ? messagesVariations.IS_CREATED_ROOM_MESSAGE
    : recommendation
    ? messagesVariations.RECOMMENDATION
    : jobId
    ? messagesVariations.JOB
    : postId
    ? messagesVariations.POST
    : userId || pageId
    ? messagesVariations.PROFILE
    : file
    ? file.type === messageFileTypes.Image
      ? messagesVariations.IMAGE
      : messagesVariations.FILE
    : messagesVariations.TEXT;
};
export default getMessageVariant;
