import { useCallback, useRef } from 'react';
import {
  QueryKeys,
  useTranslation,
  removeEmptyFromObject,
  uuId,
  useUpdateQueryData,
  useUpdateInfinityData,
} from '@lobox/utils';
import sendWSMessage from '@shared/utils/sendWSMessage';
import {
  USE_WEBSOCKET_KEYS,
  useWebSocket,
} from '@shared/utils/message-service';
import debounce from 'lodash/debounce';
import isNil from 'lodash/isNil';
import isPlainObject from 'lodash/isPlainObject';
import { useGetChatRoomsListQueryKey } from '@shared/components/Organism/Message/hooks/index';
import {
  MessageInputActions,
  messageSendingStatus,
  messagesVariations,
  messageTypes,
} from '../constants';
import {
  useMessageDispatch,
  useMessageState,
} from '../context/message/message.provider';
import type {
  IMessage,
  IWsMessage,
  ISubmitInputProps,
  IAddNewMessage,
  IReplyMessageProps,
  IEditMessage,
  IUpdateMessage,
  IForwardMessageProps,
  IDeleteMessage,
} from '@shared/types/messaging/message';
import getMessageVariant from '../utils/getMessageVariant';
import useGetMessageObject from './useGetMessageObject';
import type { IChatRoom } from '@shared/types/messaging';
import scrollToChatConversationBottom from '@shared/components/Organism/Message/utils/scrollToChatConversationBottom';

const useMessageService = () => {
  const { authObject } = useGetMessageObject();
  const { t } = useTranslation();
  const inputAction = useMessageState('inputAction');
  const activeRoom = useMessageState('activeRoom');
  const activeRoomId = activeRoom?.id;
  const selectedMessage: IMessage = useMessageState('selectedMessage');
  const dispatch = useMessageDispatch();
  const roomMessageCacheData = useUpdateQueryData<IMessage>([
    QueryKeys.getRoomMessages,
    activeRoomId,
  ]);
  const queryKey = useGetChatRoomsListQueryKey();
  const chatRoomsCacheData = useUpdateInfinityData(queryKey);
  const setServicesValueDebounced = useRef(debounce((func) => func(), 250));

  const scrollToBottom = () =>
    scrollToChatConversationBottom('chatConversation');

  const resetMessageX = () =>
    dispatch({
      type: 'MESSAGE_X_ACTION',
      payload: {
        inputAction: MessageInputActions.SEND_MESSAGE,
        message: undefined,
      },
    });

  const onMessage = useCallback(
    async (message: any) => {
      const msgData = await message.data.text();
      const wsMsg = JSON.parse(msgData);
      console.log('NEW MESSAGE', wsMsg);
      const {
        id,
        to: roomId,
        uid: userId,
        guid: messageGuId,
        text,
        parent,
        meta: file,
        ref_type: refType,
        ref: refMessageGuId,
        type: messageType,
        data: messageOptionalData,
        date: createdAt,
        last: lastSeen,
        muc,
        peer,
      } = wsMsg;
      const isAckMessage = messageType === messageTypes.MESSAGE_TYPE_ACK;
      if (messageType === messageTypes.MESSAGE_TYPE_DELIVERY_RECEIPT) {
        return console.log('MESSAGE_TYPE_DELIVERY_RECEIPT');
      }
      if (
        [
          messageTypes.MESSAGE_TYPE_TEXT,
          messageTypes.MESSAGE_TYPE_EDIT_TEXT,
          messageTypes.MESSAGE_TYPE_REPLY_TEXT,
          messageTypes.MESSAGE_TYPE_FORWARD_TEXT,
        ].includes(messageType)
      ) {
        if (muc === 1) {
          sendMessage({
            uid: authObject.id,
            id: uuId().create(),
            type: messageTypes.MESSAGE_ACK_RECEIPT,
            ref: messageGuId,
            to: roomId,
            muc: 1,
          });
        } else {
          sendMessage({
            uid: authObject.id,
            id: uuId().create(),
            ref: messageGuId,
            ref_type: messageType,
            type: messageTypes.MESSAGE_TYPE_DELIVERY_RECEIPT,
            to: userId,
            muc: undefined,
          });
        }
      }

      if (messageType === messageTypes.MESSAGE_TYPE_TEXT) {
        return addNewMessage({
          isFromServer: true,
          roomId,
          id,
          messageGuId,
          userId,
          text,
          file,
          status: messageSendingStatus.SENT,
          messageOptionalData,
          createdAt: new Date().toISOString(),
          muc,
        });
      }
      if (messageType === messageTypes.MESSAGE_TYPE_REPLY_TEXT) {
        const isMine = authObject.id === parent.uid;
        const variant = getMessageVariant({
          file: parent?.meta,
          messageOptionalData,
        });
        const parentMessage = {
          isMine,
          user: {
            id: parent.uid,
            username: messageOptionalData?.user?.username,
            fullName: messageOptionalData?.user?.ownerName,
            ownerName: isMine ? 'You' : messageOptionalData?.user?.ownerName,
          },
          id: parent.id,
          messageGuId: parent.guid,
          variant,
          visibleSeenBy: isMine,
          textAdded: parent.meta ? parent.text : undefined,
          data: {
            createdAt,
            status: messageSendingStatus.SENT,
            type: parent.type,
            isFromServer: true,
            image: parent.meta?.url,
            file: parent.meta,
            text: parent.text || '',
            wsMessage: parent,
            messageOptionalData,
          },
        };

        return replyMessage({
          roomId,
          userId,
          isFromServer: true,
          id,
          messageGuId,
          text,
          file,
          status: messageSendingStatus.SENT,
          parentMessage,
          messageOptionalData,
          muc,
        });
      }
      if (messageType === messageTypes.MESSAGE_TYPE_FORWARD_TEXT) {
        const isMine = authObject.id === parent.uid;
        const variant = getMessageVariant({
          file: parent?.meta,
          messageOptionalData,
        });

        const forwardedMessage = {
          isMine,
          user: {
            id: parent.uid,
            fullName: isMine
              ? authObject.username
              : messageOptionalData?.user?.fullName,
            ownerName: isMine
              ? authObject.username
              : messageOptionalData?.user?.ownerName,
            username: isMine
              ? authObject.username
              : messageOptionalData?.user?.username,
          },
          id: parent.id,
          messageGuId: parent.guid,
          variant,
          visibleSeenBy: isMine,
          isForward: true,
          data: {
            createdAt,
            status: messageSendingStatus.SENT,
            type: parent.type,
            isFromServer: true,
            image: parent.meta?.url,
            file: parent.meta,
            text: parent.text || '',
            wsMessage: parent,
            messageOptionalData,
          },
        };

        return forwardMessage({
          roomId,
          userId,
          isFromServer: true,
          id,
          messageGuId,
          status: messageSendingStatus.SENT,
          forwardedMessage,
          muc,
        });
      }
      if (messageType === messageTypes.MESSAGE_TYPE_ERROR) {
        console.warn({
          type: 'error',
          icon: 'times-circle',
          title: `${t('error')} ${wsMsg?.code}`,
          message: wsMsg?.text,
          id: JSON.stringify(wsMsg?.text),
        });

        return updateMessage({
          id: wsMsg.id,
          updatedMessage: {
            data: {
              status: messageSendingStatus.ERROR,
            },
          },
        });
      }
      if (messageType === messageTypes.MESSAGE_TYPE_EDIT_TEXT) {
        return updateMessage({
          id: refMessageGuId,
          updatedMessage: {
            textAdded: file ? text : undefined,
            data: {
              text,
              createdAt,
              isEdited: true,
              status: messageSendingStatus.SENT,
            },
          },
        });
      }
      if (messageType === messageTypes.MESSAGE_TYPE_DELETE_TEXT) {
        return deleteMessage({ muc, roomId, id, isFromServer: true });
      }
      if (messageType === messageTypes.MESSAGE_LAST_ACTIVITY_REPLY) {
        chatRoomsCacheData.replace({ id: peer }, (oldData: any) => ({
          ...oldData,
          lastSeen,
        }));
        return dispatch({
          type: 'SET_ACTIVE_ROOM',
          payload: {
            room: {
              ...activeRoom,
              lastSeen,
            },
          },
        });
      }
      if (messageType === messageTypes.MESSAGE_TYPE_PONG) {
        return global.__DEV__
          ? console.log('PONG, so server is fine :)')
          : null;
      }
      if (messageType === messageTypes.MESSAGE_TYPE_CONNECTION_REPLY) {
        return global.__DEV__ ? console.log('connect successfully') : null;
      }
      if (isAckMessage && refType === messageTypes.MESSAGE_TYPE_DELETE_TEXT) {
        return deleteMessage({
          roomId,
          id,
          isFromServer: true,
          muc,
        });
      }
      if (
        isAckMessage &&
        refType === messageTypes.MESSAGE_TYPE_CLEAR_HISTORY_TEXT
      ) {
        return dispatch({ type: 'CLEAR_ROOM_HISTORY', payload: { roomId } });
      }
      if (
        isAckMessage &&
        [
          messageTypes.MESSAGE_TYPE_EDIT_TEXT,
          messageTypes.MESSAGE_TYPE_REPLY_TEXT,
          messageTypes.MESSAGE_TYPE_FORWARD_TEXT,
        ].includes(refType)
      ) {
        resetMessageX();
        return updateMessage({
          id: refMessageGuId || id,
          updatedMessage: {
            data: {
              status: messageSendingStatus.SENT,
            },
          },
        });
      }
      if (isAckMessage && refType === messageTypes.MESSAGE_TYPE_TEXT) {
        resetMessageX();
        updateMessage({
          id: refMessageGuId || id,
          updatedMessage: {
            messageGuId,
            data: {
              status: messageSendingStatus.SENT,
              wsMessage: {
                guid: messageGuId,
              },
            },
          },
        });
      }
    },
    [activeRoomId]
  );

  useWebSocket(USE_WEBSOCKET_KEYS.messageService, onMessage);

  const submitInput = (props: ISubmitInputProps) => {
    const { text, file } = props;
    const isGroupChat = activeRoom?.isGroupChat;
    const muc = isGroupChat ? 1 : undefined;

    if (inputAction === MessageInputActions.SEND_MESSAGE) {
      return addNewMessage({
        roomId: activeRoomId,
        userId: authObject.id,
        id: uuId().create(),
        text,
        file,
        status: messageSendingStatus.SENDING,
        createdAt: new Date().toISOString(),
        muc,
        messageOptionalData: {
          user: {
            id: authObject.id,
            ownerName: authObject.fullName,
          },
        },
      });
    }
    if (inputAction === MessageInputActions.REPLAY_MESSAGE) {
      return replyMessage({
        roomId: activeRoomId,
        userId: authObject.id,
        id: uuId().create(),
        text,
        file,
        status: messageSendingStatus.SENDING,
        parentMessage: selectedMessage,
        messageOptionalData: {
          ownerName: authObject.fullName,
        },
        muc,
      });
    }
    if (inputAction === MessageInputActions.EDIT_MESSAGE) {
      dispatch({
        type: 'MESSAGE_X_ACTION',
        payload: {
          inputAction: MessageInputActions.SEND_MESSAGE,
          message: undefined,
        },
      });
      return editMessage({
        id: uuId().create(),
        roomId: activeRoomId,
        userId: authObject.id,
        editedMessageGuid: selectedMessage.messageGuId,
        text,
        status: messageSendingStatus.SENDING,
        muc,
      });
    }
  };

  const addNewMessage = (props: IAddNewMessage) => {
    const {
      isFromServer,
      roomId,
      userId,
      id,
      text,
      file,
      messageGuId,
      status,
      createdAt,
      messageOptionalData,
      muc,
    } = props;
    const isMine = authObject.id === userId;
    const variant = getMessageVariant({ file, messageOptionalData });
    const wsMessageType = messageTypes.MESSAGE_TYPE_TEXT;
    const wsMessage = removeEmptyFromObject({
      text: text || '',
      type: wsMessageType,
      id,
      muc,
      to: roomId,
      uid: userId,
      meta: file,
      data: messageOptionalData,
    });
    const message = {
      isMine,
      user: {
        id: userId,
        username: isMine
          ? authObject.username
          : messageOptionalData?.user?.username,
        fullName: isMine
          ? authObject.fullName
          : messageOptionalData?.user?.fullName,
        ownerName: isMine ? 'You' : messageOptionalData?.user?.ownerName,
      },
      id,
      messageGuId,
      variant,
      visibleSeenBy: isMine,
      textAdded: file ? text : undefined,
      data: {
        type: wsMessageType,
        createdAt,
        image: file?.url,
        file,
        status,
        text: text || '',
        isFromServer,
        wsMessage,
        messageOptionalData,
      },
    };
    const chatRoomId = muc || !isFromServer ? roomId : userId;
    const isActiveRoom = activeRoomId === chatRoomId;
    if (isActiveRoom) {
      roomMessageCacheData.add(message);
    }
    setServicesValueDebounced.current(() => {
      chatRoomsCacheData.replace({ id: chatRoomId }, (oldData: any) => ({
        ...oldData,
        lastMessage: message,
        unReadMessageCount:
          isActiveRoom && message.isMine
            ? oldData.unReadMessageCount
            : (oldData.unReadMessageCount || 0) + 1,
        createdAt,
      }));
    });
    scrollToBottom();
  };

  const replyMessage = (props: IReplyMessageProps) => {
    const {
      isFromServer,
      roomId,
      id,
      userId,
      parentMessage,
      text,
      file,
      messageOptionalData,
      messageGuId,
      status,
      muc,
    } = props;

    const createdAt = new Date().toISOString();
    const isMine = authObject.id === userId;
    const variant = getMessageVariant({ file });
    const wsMessageType = messageTypes.MESSAGE_TYPE_REPLY_TEXT;
    const parent = parentMessage.data.wsMessage;
    parent.guid = parentMessage.messageGuId;
    const wsMessage = {
      text: text || '',
      type: wsMessageType,
      id,
      muc,
      to: roomId,
      uid: userId,
      meta: file,
      parent,
      data: messageOptionalData,
    };
    const message = {
      isMine,
      user: {
        id: userId,
        username: isMine ? authObject.username : '-',
        fullName: isMine ? authObject.fullName : '-',
        ownerName: isMine ? 'You' : messageOptionalData?.ownerName,
      },
      id,
      messageGuId,
      variant,
      isReplied: true,
      textAdded: text,
      data: {
        type: wsMessageType,
        createdAt,
        image: file?.url,
        file,
        status,
        isFromServer,
        parentMessage,
        wsMessage,
      },
    };

    if (!isFromServer) {
      sendMessage(wsMessage);
    }
    roomMessageCacheData.add(message);
    setServicesValueDebounced.current(() =>
      chatRoomsCacheData.replace({ id: roomId }, (oldData: any) => ({
        ...oldData,
        lastMessage: message,
      }))
    );
  };

  const forwardMessage = (props: IForwardMessageProps) => {
    const { isFromServer, roomId, id, userId, forwardedMessage, status, muc } =
      props;
    const {
      data: { messageOptionalData, wsMessage: parent },
      messageGuId,
    } = forwardedMessage;
    const createdAt = new Date();
    const isMine = authObject.id === userId;
    const wsMessageType = messageTypes.MESSAGE_TYPE_FORWARD_TEXT;
    parent.guid = forwardedMessage.messageGuId;
    const wsMessage = {
      type: wsMessageType,
      id,
      muc,
      to: roomId,
      uid: userId,
      parent,
      data: {
        ownerName: authObject.fullName,
      },
    };
    const message = {
      isMine,
      user: {
        id: userId,
        username: isMine ? authObject.username : '-',
        fullName: isMine ? authObject.fullName : '-',
        ownerName: isMine ? 'You' : messageOptionalData?.ownerName,
      },
      id,
      messageGuId,
      variant: messagesVariations.TEXT,
      isForward: true,
      data: {
        createdAt,
        status,
        type: messageTypes.MESSAGE_TYPE_FORWARD_TEXT,
        isFromServer,
        parentMessage: forwardedMessage,
        wsMessage,
      },
    };
    if (!isFromServer) {
      sendMessage(wsMessage);
    }
    roomMessageCacheData.add(message);
    setServicesValueDebounced.current(() =>
      chatRoomsCacheData.replace({ id: roomId }, (oldData: any) => ({
        ...oldData,
        lastMessage: message,
      }))
    );
  };

  const editMessage = (props: IEditMessage) => {
    const { isFromServer, roomId, editedMessageGuid, text, status, muc } =
      props;
    if (!isFromServer) {
      sendMessage({
        uid: authObject.id,
        id: uuId().create(),
        type: messageTypes.MESSAGE_TYPE_EDIT_TEXT,
        to: roomId,
        text,
        ref: editedMessageGuid,
        muc,
        meta: selectedMessage.data.wsMessage.meta,
      });
    }
    updateMessage({
      id: selectedMessage.id,
      updatedMessage: {
        textAdded: selectedMessage.variant === 'TEXT' ? undefined : text,
        data: {
          text,
          status,
          isEdited: true,
          wsMessage: {
            ...selectedMessage.data.wsMessage,
            text,
          },
        },
      },
    });
  };

  const sendMessage = (wsMessage: IWsMessage) => {
    sendWSMessage(wsMessage);
  };

  const deleteMessage = ({
    id,
    roomId,
    messageGuId,
    isFromServer,
    muc,
    both,
  }: IDeleteMessage) => {
    if (isFromServer) {
      updateMessage({
        id,
        updatedMessage: {
          isDeleted: true,
          data: {
            text: '',
          },
        },
      });
    } else {
      sendMessage({
        uid: authObject.id,
        id,
        type: messageTypes.MESSAGE_TYPE_DELETE_TEXT,
        to: roomId,
        ref: messageGuId,
        both,
        muc,
      });
    }
    setServicesValueDebounced.current(() =>
      chatRoomsCacheData.replace({ id: roomId }, (oldData: any) => ({
        ...oldData,
        lastMessage: {
          ...oldData.lastMessage,
          isDeleted: true,
          data: {
            ...oldData.lastMessage.data,
            type: messageTypes.MESSAGE_TYPE_DELETE_TEXT,
          },
        },
      }))
    );
  };

  const seenMessage = (message: IMessage, room: IChatRoom) => {
    const {
      messageGuId,
      data: { type },
    } = message;
    const isGroupChat = room?.isGroupChat;
    const muc: 1 | undefined = isGroupChat ? 1 : undefined;
    const wsSendMsg = {
      uid: authObject.id,
      id: uuId().create(),
      type: messageTypes.MESSAGE_TYPE_SEEN,
      to: activeRoomId,
      ref: messageGuId,
      ref_type: type,
      muc,
    };
    sendMessage(wsSendMsg);
  };

  const updateMessage = ({ id, updatedMessage }: IUpdateMessage) => {
    const cacheData = roomMessageCacheData.getAll();

    if (!isNil(cacheData) && isPlainObject(cacheData)) {
      const index = cacheData.content.findIndex(
        (x: any) => x.id === id || x.messageGuId === id
      );
      if (index > -1) {
        const { data = {}, ...rest } = cacheData.content[index];
        const { data: newData = {}, ...newRest } = updatedMessage;
        const { wsMessage = {}, profile = {} } = data;
        const { wsMessage: newWsMessage = {}, profile: newProfile = {} } =
          newData;
        const updatedData = {
          ...data,
          ...newData,
          wsMessage: { ...wsMessage, ...newWsMessage },
          profile: { ...profile, ...newProfile },
        };
        cacheData.content[index] = {
          ...rest,
          ...newRest,
          data: updatedData,
        };
      }
      roomMessageCacheData.replaceAll({ content: cacheData.content });
    }
  };

  return {
    addNewMessage,
    replyMessage,
    forwardMessage,
    editMessage,
    submitInput,
    sendMessage,
    deleteMessage,
    seenMessage,
    updateMessage,
  };
};

export default useMessageService;
