import { uuId } from '@lobox/utils';
import type { IChatRoom } from '@shared/types/messaging';
import { messageTypes } from '../constants';
import { useMessageDispatch } from '../context/message/message.provider';
import useMessageService from './useMessageService';
import useGetMessageObject from './useGetMessageObject';

const useClickChatRoomItem = () => {
  const messageDispatch = useMessageDispatch();
  const { authObject } = useGetMessageObject();
  const messageService = useMessageService();

  const clickHandler = (item: IChatRoom) => {
    if (!item.isGroupChat) {
      messageService.sendMessage({
        uid: authObject.id,
        id: uuId().create(),
        type: messageTypes.MESSAGE_LAST_ACTIVITY_REQUEST,
        peer: item.id,
        muc: item.isGroupChat ? 1 : undefined,
      });
    }

    messageDispatch({
      type: 'SET_ACTIVE_ROOM',
      payload: { room: item },
    });
    messageDispatch({
      type: 'OPEN_MESSAGE_CONVERSATION',
    });
  };
  return clickHandler;
};

export default useClickChatRoomItem;
