import { useInfiniteQuery } from '@tanstack/react-query';
import type {
  UseInfiniteQueryResult,
  QueryKey,
  QueryFunction,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';
import type { PaginateResponse } from '@lobox/utils';

export type UseMessageInfiniteQueryType<
  TData = unknown,
  TError = unknown,
> = Omit<UseInfiniteQueryResult<TData, TError>, 'data'> & {
  data: Array<TData>;
  totalElements: number;
};

const useMessageInfiniteQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn: {
    func: QueryFunction<TQueryFnData, TQueryKey> | Function;
    size?: number;
    extraProps?: {
      [key: string]: any;
    };
  },
  options?: UseInfiniteQueryOptions<
    TQueryFnData,
    TError,
    TData,
    TQueryFnData,
    TQueryKey
  >
): UseMessageInfiniteQueryType<TData, TError> => {
  const result = useInfiniteQuery<PaginateResponse<TData>>({
    queryKey,
    queryFn: (props: any) =>
      queryFn.func({
        // @ts-ignore
        page: props.pageParam,
        size: queryFn.size,
        ...(queryFn.extraProps || {}),
      }),
    ...(options as any),
    getNextPageParam: (lastPage) => {
      const lastPageIndex = lastPage?.content?.length - 1;
      return lastPage?.content?.[lastPageIndex];
    },
  });
  const pages = result.data?.pages;

  const a: Array<TData> = [];
  pages?.forEach((page) =>
    page.content.forEach((contentItem) => {
      a.push(contentItem);
    })
  );

  return {
    ...result,
    data: a,
    totalElements: parseInt(result?.data?.pages[0]?.totalElements || '0', 10),
  } as UseMessageInfiniteQueryType<TData, TError>;
};

export default useMessageInfiniteQuery;
