import useGetAppObject from '@shared/hooks/useGetAppObject';
import { getRoomMemberInfo } from '@shared/components/Organism/Message/utils/api/room';
import { QueryKeys, useReactQuery } from '@lobox/utils';
import { roomMemberRoles } from '@shared/components/Organism/Message/constants';
import type { IChatRoom } from '@shared/types/messaging';
import { useMessageState } from '../context/message/message.provider';

type UseGetMessageObjectType = {
  authObject: {
    id: string;
    fullName: string;
    username: string;
    croppedImageUrl: string;
    isAdmin?: boolean;
    isOwner?: boolean;
  };
};

const useGetMessageObject = (): UseGetMessageObjectType => {
  const { getAppObjectPropValue } = useGetAppObject();
  const activeRoom = useMessageState('activeRoom');
  const id = getAppObjectPropValue({
    pageKey: 'id',
    userKey: 'id',
  });
  const params = { userId: <string>id, groupId: <string>activeRoom?.id };

  const { data: details } = useReactQuery<IChatRoom>({
    action: {
      key: [QueryKeys.getRoomMemberInfo, activeRoom?.id, id],
      apiFunc: getRoomMemberInfo,
      params,
    },
    config: {
      enabled: !!activeRoom?.isGroupChat,
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: 60 * 60 * 1000,
    },
  });

  const fullName = getAppObjectPropValue({
    pageKey: 'title',
    userKey: 'fullName',
  });

  const username = getAppObjectPropValue({
    pageKey: 'username',
    userKey: 'username',
  });
  const croppedImageUrl = getAppObjectPropValue({
    pageKey: 'croppedImageUrl',
    userKey: 'croppedImageUrl',
  });

  const authObject = {
    id,
    fullName,
    username,
    croppedImageUrl,
    isAdmin: details?.role === roomMemberRoles.Admin,
    isOwner: details?.role === roomMemberRoles.Owner,
  };
  return { authObject };
};

export default useGetMessageObject;
