import { useState } from 'react';
import useFileUpload from '@shared/hooks/api-hook/useFileUpload';
import useMessageBubble from './useMessageBubble';

type UseUploadMessageFileProps = {
  onSuccess: (data: { link: string }) => any;
  onError: (error: Error) => void;
};

type UploadFileProps = {
  original: File;
  id: string;
};

const useUploadMessageFile = ({
  onSuccess,
  onError,
}: UseUploadMessageFileProps) => {
  const [progress, setProgress] = useState(0);
  const messageBubble = useMessageBubble();
  const { upload, cancelUpload } = useFileUpload();

  const uploadHandler = ({ id, original }: UploadFileProps) => {
    messageBubble.updateMessage({
      id,
      newData: {
        data: {
          status: 'META_UPLOADING',
        },
      },
    });
    upload(
      {
        file: original,
        multiSizeImage: true,
        onProgress: (prg: number) => {
          setProgress(prg);
        },
      },
      {
        onSuccess: (response: any) => {
          messageBubble.updateMessage({
            id,
            newData: {
              data: { status: 'SENDING' },
            },
          });
          onSuccess(response.data);
        },
        onError: (e: Error) => {
          messageBubble.updateMessage({
            id,
            newData: {
              data: { status: 'META_ERROR' },
            },
          });
          onError(e);
        },
      }
    );
  };

  return { uploadHandler, cancelUpload, progress };
};

export default useUploadMessageFile;
