import urls from '@shared/constants/urls';
import { useEffect, useRef } from 'react';

class MessageService {
  _socket: any = null;
  _callbacks: Array<{ key: string; callback: Function }> = [];

  connect(onOpenCallback: Function) {
    this._socket = new WebSocket(urls.wsUrl);

    this._socket.onopen = () => {
      console.log('websocket openned');
      onOpenCallback();
      this._socket.onclose = () => {
        console.log(`websocket onclose called`);
        this.connect(onOpenCallback);
      };
    };

    this._socket.onmessage = (message: any) => {
      this._callbacks.forEach((item) => item?.callback(message));
    };

    this._socket.onerror = (err: any) => {
      console.log('websocket', err);
    };
    const disconnect = () => {
      this._socket.close();
    };
    return disconnect;
  }

  subscribe(key: string, callback: Function) {
    this._callbacks = this._callbacks.filter(
      (callback) => callback.key !== key
    );
    this._callbacks.push({ key, callback });
    const unsubscribe = () => {
      this._callbacks = this._callbacks.filter(
        (_callback) => _callback.key !== key
      );
    };
    return unsubscribe;
  }

  get socket() {
    return this._socket;
  }

  sendMessage(message: any) {
    if (this._socket.readyState === WebSocket.OPEN) {
      const stringifiedMessage = JSON.stringify(message);
      this._socket.send(stringifiedMessage);
    } else {
      this._socket.addEventListener('open', function (event) {
        console.warn('OPEN send the message');
      });
    }
  }
}

const MessageServiceInstance = new MessageService();

export function useInitializeWebSocket(
  onOpenCallback: Function,
  options: { enabled?: boolean }
) {
  useEffect(() => {
    if (!options?.enabled) return;
    const disconnect = MessageServiceInstance.connect(onOpenCallback);
    return () => {
      disconnect();
    };
  }, [options?.enabled]);
}

export function useWebSocket(key: string, callback: Function) {
  useEffect(() => {
    const unsubscribe = MessageServiceInstance.subscribe(key, callback);

    return () => {
      unsubscribe();
    };
  }, [callback]);

  function sendMessage(message: any) {
    return MessageServiceInstance.sendMessage(message);
  }

  return { sendMessage };
}

export function sendWSMessage(message: any) {
  return MessageServiceInstance.sendMessage(message);
}

export const USE_WEBSOCKET_KEYS = {
  socketConfig: 'socketConfig',
  messageService: 'messageService',
};
