import type { IMessage, SectionNameTypes } from '@shared/types/messaging';

export type Action =
  | {
      type: 'SET_STATUS';
      payload: { status: 'SENT' | 'LOADING' | 'ERROR' };
    }
  | {
      type: 'RESET_INIT_VALUE';
      payload: { message: IMessage; sectionName: SectionNameTypes };
    }
  | { type: 'UPDATE_MESSAGE'; payload: { id: string; newData: IMessage } };

export type State = { message: IMessage; sectionName?: SectionNameTypes };

function messageBubbleReducer(state: State, action: Action) {
  switch (action.type) {
    case 'RESET_INIT_VALUE': {
      return {
        message: action.payload.message,
        sectionName: action.payload.sectionName,
      };
    }
    case 'SET_STATUS': {
      return {
        message: {
          ...state.message,
          data: { ...state.message.data, status: action.payload.status },
        },
      };
    }
    case 'UPDATE_MESSAGE': {
      const { id, newData } = action.payload;
      const { data: newUpdatedData = {}, ...newRest } = newData;
      const {
        wsMessage = {},
        profile = {},
        post = {},
        recommendation = {},
        ...rest
      } = state.message.data;
      const {
        wsMessage: newWsMessage = {},
        profile: newProfile = {},
        post: newPost = {},
        recommendation: newRecommendation = {},
        ...restNewUpdatedData
      } = newUpdatedData;
      const updatedData = {
        ...rest,
        ...restNewUpdatedData,
        wsMessage: { ...wsMessage, ...newWsMessage },
        profile: { ...profile, ...newProfile },
        post: { ...post, ...newPost },
        recommendation: { ...recommendation, ...newRecommendation },
      };

      return id === state.message.id
        ? {
            ...state,
            message: {
              ...state.message,
              ...newRest,
              data: updatedData,
            },
          }
        : state;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export default messageBubbleReducer;
