import {
  useMessageBubbleDispatch,
  useMessageBubbleState,
} from '../context/messageBubble/messageBubble.provider';
import type {
  IMessage,
  DeepPartial,
  SectionNameTypes,
} from '@shared/types/messaging';

interface UseChatBubbleProps {
  message: IMessage;
  sectionName: SectionNameTypes;
  updateMessage: ({
    id,
    newData,
  }: {
    id: string;
    newData: DeepPartial<IMessage>;
  }) => void;
}

const useMessageBubble = (): UseChatBubbleProps => {
  const dispatch = useMessageBubbleDispatch();
  const { message, sectionName } = useMessageBubbleState();

  const updateMessage = ({ id, newData }: any) => {
    dispatch({
      type: 'UPDATE_MESSAGE',
      payload: {
        id,
        newData,
      },
    });
  };

  return {
    message,
    sectionName,
    updateMessage,
  };
};

export default useMessageBubble;
