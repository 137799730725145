import { useReactMutation, useUpdateInfinityData } from '@lobox/utils';
import { useGetChatRoomsListQueryKey } from '@shared/components/Organism/Message/hooks/index';
import type { IChatRoom } from '@shared/types/messaging';
import { archiverApi } from '../utils/api';
import useGetMessageObject from './useGetMessageObject';
import {
  useMessageDispatch,
  useMessageState,
} from '../context/message/message.provider';

const useArchiveHandler = () => {
  const { authObject } = useGetMessageObject();
  const queryKey = useGetChatRoomsListQueryKey();
  const messageDispatch = useMessageDispatch();
  const activeRoom = useMessageState('activeRoom');
  const chatRoomsCacheData = useUpdateInfinityData(queryKey);

  const { mutate: onArchive, ...rest } = useReactMutation({
    apiFunc: archiverApi.archiveChatRoom,
  });

  const archiveRoomHandler = (room: IChatRoom) => () =>
    onArchive(
      {
        from: authObject.id,
        to: room.id,
        pubsub: room.isGroupChat ? 1 : 0,
      },
      {
        onSuccess: () => {
          chatRoomsCacheData.remove(room.id);
          if (room.id === activeRoom?.id) {
            messageDispatch({
              type: 'CLOSE_MESSAGE_CONVERSATION',
            });
            messageDispatch({
              type: 'CLOSE_CONTACT_INFO',
            });
          }
        },
      }
    );

  return { archiveRoomHandler, ...rest };
};

export default useArchiveHandler;
